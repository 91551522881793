.ul {
    color: white;
}

.nav ul {
    padding: 0;
    margin: 0;
    list-style: none;
    color: white;
    gap: 1rem;
}

.nav ul li {
    width:17%;
}

.cheat.nav ul li {
    width:100%;
}


.nav a{
    color: white;
    text-decoration: none;
}

.nav li.active {
    text-decoration: underline;
}

.nav li:hover {
    font-weight: bold;
}
@font-face {
  font-family: 'Header';
  src: local('Header'), url(./assets/fonts/NimbusRomNo9L-Reg.otf) format('truetype');
}

@font-face {
  font-family: 'Aerotis';
  src: local('Aerotis'), url(./assets/fonts/Aerotis.ttf) format('truetype');
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columns */
  gap: 10px; /* Space between images */
  padding: 10px;
}

.grid-image {
  width: 100%;
  height: 150px; /* Fixed height, adjust as needed */
  object-fit: cover; /* Crop the images to fit the grid without stretching */
  border-radius: 8px; /* Optional: Rounded corners for a polished look */
}

.upload-container {
  text-align: center; /* Center the button */
  margin-bottom: 20px; /* Add some space below the button */
}

.upload-button {
  display: inline-block;
  background-color: white; /* Green background */
  color: black; /* White text */
  padding: 10px 20px; /* Padding for the button */
  font-size: 16px; /* Font size for the button text */
  text-align: center; /* Center the text */
  text-decoration: none; /* Remove underline from the link */
  border-radius: 5px; /* Rounded corners */
  border: none; /* Remove border */
  cursor: pointer; /* Add a pointer cursor on hover */
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Fill parent container (full height) */
  width: 100%;  /* Fill parent container (full width) */
  text-align: center;
}

.upload-button:hover {
  background-color: goldenrod; /* Darker green on hover */
}

body, html {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

p {
  font-size: 1rem;
}

.w-65 {
  width: 65%;
}

.image-container {
  height: 18vh;
  width: 33%;
  margin-right: 0.5em;
  margin-left: 0.5em;
}

.large-image-container {
  height: 80%;
  width: 80%;
}

/* Resize images */
.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.beach-yara-photo img {
  object-position: 70%;
}

.old-photo img {
  object-position: 100%;
}

.old-photo2 img {
  object-position: 10%;
}

/* Resize images */
.large-image-container img {
  width: 100%;
  height: 100%;
}

.image-gallery {
  gap:1rem;
}

.image-gallery > li {
  flex-basis: 350px; /* width: 350px; */
}

.image-gallery li img {
  object-fit: cover;
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  border-radius: 5px;
}

.App {
  text-align: center;
  background-color: #5E5E3E;
  min-height: 100vh;
  display: flex;
  flex-flow: column;
}

.Aerotis-Font {
  font-family: 'Aerotis';
}

.Header-Font {
  font-family: 'Header';
  color: white;
}

.Title-Header {
  margin: 1.5rem;
}

.weeks-font {
  font-size: 2em;
}

.nav-bar-border {
  border-top-style: solid;
  border-bottom-style: solid;
  margin-left: 1em;
  margin-right: 1em;
  border-width: 0.1em;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

.App-logo {
  height: 30vmin;
  pointer-events: none;
}

.Header {
  font-size: 5.5em; 
}

.Subheader-Font {
  font-size: 3.5em; 
  font-family: 'Cursive';
}

.Subheader-Small-Font {
  font-size: 2em; 
}

.radioLabel {
  font-size: 1.5em;
}

.radioBtn {
  accent-color: #534E2D;
  margin-right: 0.5em;
}

.App-header {
  background-color: #5E5E3E;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(2vmin);
  color: white;
}

.App-body {
  background-color: #5E5E3E;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(2vmin);
  color: white;
}

.Min-Height-60 {
  min-height: 60vh;
}

.Footer {
  font-size: 0.75em;
  margin-top: 3em;
  height: 4em;
}

.name {
  font-size: 1.5em;
}

.field {
  width: 50%;
  height: 2rem;
  border-radius: 4px;
  position: relative;
}

.field:hover {
  background-color: rgba(255, 255, 255, 0.45);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
}

.venue {
  margin-bottom: 5em;
}

.custom-btn {
  background-color: rgb(255, 255, 255);
  border-color: #534E2D;
  border: 3px solid-transparent;
  color: rgb(255, 255, 255);
  border-radius: 100px;
  height: 2rem;
  width: 5rem;
  font-size: 0.8rem;
  color: #534E2D;
  font-weight: 700
}

.toast-font {
  color: black;
}

.App-link {
  color: #61dafb;
}

.Us-Photo {
  pointer-events: none;
  width: 100%;
  object-fit: cover;
}

.nav ul li {
  align-content: center;
  display: flex;
  justify-content: center;
}

.navbar {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.7rem 2rem;
  overflow: hidden;
  z-index: 1;
  width: 88.15%;
  margin: auto;
  top: 0;
  border-bottom: solid 1px var(--primary-color);
  opacity: 0.9;
  top: 0;
  box-shadow: 12px 0 15px -4px rgba(31, 73, 125, 0.8),
    -12px 0 8px -4px rgba(31, 73, 125, 0.8);
  box-shadow: 0 9px 0px 0px white, 0 -9px 0px 0px white,
    12px 0 15px -4px rgba(31, 73, 125, 0.8),
    -12px 0 15px -4px rgba(31, 73, 125, 0.8);
}
